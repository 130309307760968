/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function PageTemplate({ data, location }) {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const post = data.markdownRemark
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <header>
        <h1 itemProp="headline">{post.frontmatter.title}</h1>
      </header>
      <section
        className="post-body"
        dangerouslySetInnerHTML={{ __html: post.html }}
        itemProp="articleBody"
      />
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export const pageQuery = graphql`
  query PageBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "DD [de]  MMMM, YYYY", locale: "es")
        description
      }
    }
  }
`
